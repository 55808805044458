var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "Login base-height" }, [
        _c("div", { staticClass: "login_left" }),
        _c("div", { staticClass: "mainBox" }, [
          _c(
            "h1",
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/reviewmind/logo2.png"),
                    alt: "로고"
                  }
                })
              ])
            ],
            1
          ),
          _c("h2", [_vm._v(_vm._s(_vm.$t("login_title")))]),
          _c("div", { staticClass: "form" }, [
            _c("form", [
              _c("fieldset", [
                _c("legend", [_vm._v("로그인")]),
                _c("div", { staticClass: "cover bg" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email"
                      }
                    ],
                    staticClass: "error",
                    attrs: { type: "email", placeholder: _vm.$t("inputEmail") },
                    domProps: { value: _vm.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.email = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "em",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errMsg,
                          expression: "errMsg"
                        }
                      ],
                      staticClass: "errMsg"
                    },
                    [_vm._v(_vm._s(_vm.$t("loginErrMsg")))]
                  )
                ]),
                _c("div", { staticClass: "cover bg" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password"
                      }
                    ],
                    attrs: {
                      type: "password",
                      placeholder: _vm.$t("inputPwd")
                    },
                    domProps: { value: _vm.password },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.password = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "em",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errMsg,
                          expression: "errMsg"
                        }
                      ],
                      staticClass: "errMsg"
                    },
                    [_vm._v(_vm._s(_vm.$t("loginErrMsg")))]
                  )
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn full primary",
                    attrs: { type: "button" },
                    on: { click: _vm.submit }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("emailLogin")) + " ")]
                ),
                _vm._m(0),
                _c(
                  "button",
                  {
                    staticClass: "btn full facebookColor",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.logInWithFacebook()
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/logo_facebook.png")
                      }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("facebookLogin")) + " ")
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn full googleColor",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.googleLogin()
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/images/logo_google.png") }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("googleLogin")) + " ")
                  ]
                )
              ])
            ])
          ]),
          _c(
            "aside",
            { staticClass: "flexL" },
            [
              _c(
                "router-link",
                { staticClass: "btn", attrs: { to: "/join" } },
                [_vm._v(_vm._s(_vm.$t("join")))]
              ),
              _c(
                "router-link",
                { staticClass: "btn", attrs: { to: "/findEmail" } },
                [_vm._v(_vm._s(_vm.$t("findId")))]
              ),
              _c(
                "router-link",
                { staticClass: "btn", attrs: { to: "/findPassword" } },
                [_vm._v(_vm._s(_vm.$t("findPwd")))]
              )
            ],
            1
          )
        ])
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.visible
          ? _c("div", { staticClass: "dim on" }, [_c("AuthCode")], 1)
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "or" }, [
      _c("span"),
      _c("p", [_vm._v("OR")]),
      _c("span")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }